<template>
  <v-card class="custom-card d-flex flex-column justify-space-between custom-border" max-width="400" elevation="4">
    <section class="cursor-pointer" @click="navigateToCourseDetails">
      <!-- Image with overlay text -->
      <div class="relative">
        <img
          width="380"
          height="150"
          :src="courseImage"
          class="rounded-t"
          @error="handleImageError"
        />
      </div>

      <!-- Status badges -->
      <div class="badge-container">
          <div v-if="forRegistration" class="status-label open-label">Open</div>
          <div v-else class="status-label closed-label">Closed</div>
          <div v-if="isEnrolled" class="status-label enrolled-label">Enrolled</div>
      </div>

      <!-- Course Details -->
      <v-card-subtitle class="poppins fw600 pb-0 mb-0">
        {{ courseTitle }}
        <div class="date-range poppins fw500 f10 py-0">
          Start: {{ formattedStartDate }} ({{ formattedStartTime }}) - End: {{ formattedEndDate }} ({{ formattedEndTime }})
        </div>
        <div class="access-levels poppins fw500 f10 py-0">
          Access Level:
          <v-chip
              v-for="level in accessLevels"
              :key="level"
              class="access-chip"
              small
          >
              {{ level }}
          </v-chip>
        </div>
      </v-card-subtitle>
    </section>

    <!-- Learn more button -->
    <section class="learn-more-section">
      <v-container>
        <v-row no-gutters>
          <v-col cols="12">
            <button class="learn-more-btn" @click="navigateToCourseDetails">
              Learn More
            </button>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </v-card>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  name: "GalleryCard",
  props: ["course"],
  data: () => ({
    loading: false,
  }),
  computed: {
    ...mapState("usr", {
      cart: (state) => state.cart,
      user_courses: (state) => state.courses || [], // Provide a default empty array
    }),
    getPrimary() {
      return this.$vuetify.theme.currentTheme.primary;
    },
    getSecondary5() {
      return this.$vuetify.theme.currentTheme["secondary-5"];
    },
    forRegistration() {
      if (this.course.available_until) {
        let today = new Date();
        let startDate = this.course.available_until.start_date
          ? new Date(this.course.available_until.start_date)
          : null;
        let endDate = this.course.available_until.end_date
          ? new Date(this.course.available_until.end_date)
          : null;

        if (startDate && endDate) {
          return today >= startDate && today <= endDate;
        } else if (startDate) {
          return today >= startDate;
        } else if (endDate) {
          return today <= endDate;
        }
      }
      return false;
    },
    courseImage() {
      return this.course.image
        ? this.course.image.url
        : require('@/assets/images/landing/background/default-course-bg.png');
    },
    isEnrolled() {
      return this.user_courses.find(i => i.uuid === this.course.uuid);
    },
    courseTitle() {
      return this.course.title ? this.course.title : "Untitled";
    },
    formattedStartDate() {
      return this.course.available_until?.start_date ? this.formatDate(this.course.available_until.start_date) : "N/A";
    },
    formattedEndDate() {
      return this.course.available_until?.end_date ? this.formatDate(this.course.available_until.end_date) : "N/A";
    },
    formattedStartTime() {
      return this.formatTime(this.course.available_until?.start_time);
    },
    formattedEndTime() {
      return this.formatTime(this.course.available_until?.end_time);
    },
    accessLevels() {
      return this.course.year_level_accesses.map((item) => item.year_level);
    },
  },
  methods: {
    handleImageError(event) {
      event.target.src = require('@/assets/images/landing/background/default-course-bg.png');
    },
    formatDate(date) {
      return this.$dateFormat.mmDDyy(date);
    },
    formatTime(timeString) {
      if (!timeString) return '';
      const date = new Date(`1970-01-01T${timeString}`);
      return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    },
    navigateToCourseDetails() {
      this.$router.push({
        name: 'User Other Course Details',
        params: { uuid: this.course.uuid },
        query: { timestamp: Date.now() },
      });
    },
  },
};
</script>

<style scoped>
.custom-card {
  min-height: 400px; /* Ensure cards have a minimum height for alignment */
}

.relative {
  position: relative;
}

.badge-container {
  display: flex;
  gap: 10px;
  padding: 10px;
}

.status-label {
  display: inline-block;
  padding: 4px 8px;
  font-size: 12px;
  font-weight: 500;
  border-radius: 8px;
  margin-right: 4px;
}

.open-label {
  background-color: #e0e7ff;
  color: #1E3988;
}

.closed-label {
  background-color: #e0e7ff;
  color: #E81A1A;
}

.enrolled-label {
  background-color: #d1fae5;
  color: #1FDF67;
}

.date-range,
.access-levels {
  margin-top: 8px;
}

.access-chip {
  font-size: 10px;
  padding: 4px 8px;
  background-color: #f0f0f0;
  margin-left: 4px;
  border-radius: 4px;
}

.learn-more-section {
  padding: 16px;
}

.learn-more-btn {
  background-color: #f0f0f0;
  border: none;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.87);
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  padding: 12px 24px;
  text-align: center;
  transition: background-color 0.3s ease;
  width: 100%;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

.learn-more-btn:hover {
  background-color: #e0e0e0;
}
</style>
