<template>
    <v-card
        class="d-flex flex-column justify-space-between custom-border"
        max-width="250"
        elevation="2"
    >
        <section class="cursor-pointer">
            <img
                v-if="data.image"
                width="250"
                height="132"
                :src="data.image.url"
                v-on:error="require('@/assets/images/course-placeholder-1.png')"
                class="rounded-t"
            />
            <v-sheet
                v-if="!data.image"
                width="250"
                height="132"
                :color="
                    !data.image
                        ? data.course_card
                            ? data.course_card.color
                            : selectedColor
                        : 'primary'
                "
                class="hover border-top"
            >
                <v-menu offset-y v-if="!data.image">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            icon
                            color="white"
                            class="float-right"
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-icon> mdi-dots-vertical </v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item>
                            <v-menu
                                right
                                offset-x
                                :close-on-content-click="menu"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        left
                                        text
                                        v-bind="attrs"
                                        v-on="on"
                                        class="text-capitalize poppins f12"
                                    >
                                        Change Color
                                        <v-icon right>mdi-chevron-right</v-icon>
                                    </v-btn>
                                </template>
                                <v-sheet width="250">
                                    <v-color-picker
                                        hide-canvas
                                        hide-inputs
                                        hide-sliders
                                        hide-mode-switch
                                        class="pa-2"
                                        mode="rgba"
                                        show-swatches
                                        swatches-max-height="150"
                                        v-model="selectedColor"
                                        @update:color="changeColor"
                                    />
                                </v-sheet>
                            </v-menu>
                        </v-list-item>
                        <v-list-item>
                            <v-btn
                                text
                                class="text-capitalize poppins f12"
                                @click="changeFavorite"
                            >
                                Mark as
                                {{ favorite ? "Unfavorite" : "Favorite" }}
                                <v-icon color="yellow" right v-if="favorite">
                                    mdi-star
                                </v-icon>
                                <v-icon color="yellow" right v-else>
                                    mdi-star-outline
                                </v-icon>
                            </v-btn>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-sheet>
            <section class="d-flex flex-column">
                <v-card-subtitle class="poppins fw600 pb-0 mb-0 break-word">
                    {{ data.title ? data.title : "Untitled" }}
                    <!-- {{ data.title.length > 35 ? `${data.title.slice(0, 36)}...` : data.title}} -->
                    <div class="poppins fw600 py-0 f10">
                        {{ data.uploader.full_name }}
                        <!-- {{ data.training_hours }} {{ data.training_hours > 1 ? 'hours' : 'hour' }} • {{ data.modules_count ? data.modules_count : 0 }} {{ data.modules_count > 1 ? 'modules' : 'module'}}  -->
                    </div>
                    <div
                        v-if="data.available_until"
                        class="poppins primary-font fw500 f10 py-0"
                    >
                        <span v-if="data.available_until.start_date">
                            from
                            {{
                                $dateFormat.mmDDyy(
                                    data.available_until.start_date,
                                )
                            }}
                        </span>
                        <span v-if="data.available_until.end_date">
                            until
                            {{
                                $dateFormat.mmDDyy(
                                    data.available_until.end_date,
                                )
                            }}
                        </span>
                    </div>
                    <div class="poppins primary-font fw500 f10 py-0">
                        Access Level's:
                        <span
                            v-for="(item, i) in data.year_level_accesses"
                            :key="i"
                            class="px-2 py-1 grey lighten-4 ma-1"
                        >
                            {{ item.year_level }}
                        </span>
                    </div>
                </v-card-subtitle>
            </section>
        </section>
        <section class="cursor-pointer">
            <v-container class="mx-1">
                <v-progress-linear
                    height="8"
                    :value="progress"
                    rounded
                    color="success"
                    background-color="grey lighten-3"
                ></v-progress-linear>
                <b class="poppins secondary-1--text fw600 f13 my-2">
                    {{ progress }}% Complete
                </b>
                <v-row no-gutters :style="'flex: inherit;'">
                    <v-col
                        :class="buttonClass"
                        align="center"
                        class="pa-2 white--text d-flex justify-center align-center f12 fw500 poppins rounded-br"
                        @click="handleClick()"
                        :style="buttonStyle"
                        
                    >
                        {{ buttonText }}
                    </v-col>
                </v-row>
            </v-container>
            <!-- <v-btn block tile flat class="py-5 poppins f12 rounded-b text-capitalize" color="primary" @click="$router.push({name: 'User Course Details', params: {id: data.uuid}})">
                {{ progress === 0 ? 'Start Course' : progress === 100 ? 'Completed' : 'Continue Course'}}
            </v-btn> -->
        </section>
    </v-card>
</template>

<script>
import { mapActions } from "vuex";

export default {
    props: ["data", "progress"],
    data: () => ({
        menu: false,
        favorite: false,
        selectedColor: "#1565C0FF",
        asset_path: `${process.env.VUE_APP_ASSET_PATH}`,
    }),
    computed: {
        isButtonDisabled() {
            const { available_until, prerequisite, sort } = this.data;
            let today = new Date().toISOString().slice(0, 10);
            
            // Check if the end date has passed
            if (available_until && available_until.end_date) {
                const endDate = new Date(available_until.end_date).toISOString().slice(0, 10);
                console.log(`Today: ${today}, End Date: ${endDate}`);  // Debugging line
                if (today > endDate) {
                    return true;
                }
            }

            // Check if prerequisites are not completed
            if (!prerequisite?.course_prerequisite?.completed_course) {
                console.log('Prerequisite not completed');  // Debugging line
                return true;
            }

            // Check for sorting logic
            if (sort === 1) {
                return false;
            }

            return false;
        },



        buttonClass() {
            return this.isButtonDisabled ? "disabled-button" : "pointer-cursor";
        },
        buttonStyle() {
            return {
                backgroundColor: this.isButtonDisabled ? "grey" : "#1E3988",
                cursor: this.isButtonDisabled ? "not-allowed" : "pointer",
            };
        },

        buttonText() {
            let today = new Date().toISOString().slice(0, 10);
            
            // If end date has passed, change text to "Close"
            if (this.data.available_until && this.data.available_until.end_date && today > this.data.available_until.end_date) {
                return "Close";
            }

            if (this.progress === 0) return "Start Course";
            if (this.progress === 100) return "Completed";
            return "Continue Course";
        },

    },
    methods: {
        ...mapActions("usr", [
            "updateCourseColor",
            "updateCourseFavorite",
            "updateCourseFavorite",
        ]),

        changeColor() {
            if (this.data.course_card) {
                this.data.course_card.color = this.selectedColor;
            }

            this.updateCourseColor({
                course_id: this.data.id,
                color: this.selectedColor,
            });
        },

        changeFavorite() {
            this.favorite = !this.favorite;

            this.updateCourseFavorite({
                course_id: this.data.id,
                is_favorite: this.favorite,
            });
        },
        handleClick() {
            if (!this.isButtonDisabled) {
                this.$router.push({
                    name: "User Course Details",
                    params: { id: this.data.uuid },
                });
            }
        },
    },
};
</script>
